














































































































import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import ProfileView from "@/views/ProfileView.vue";
import {VForm} from "@/types/types"
import axios from "axios";
import FormSubmission from "@/types/FormSubmission";

@Component({
  components: {ProfileView},
})
export default class ContactForm extends Vue {
  @Prop() isContactFormVisible!: boolean;
  @Ref("form") readonly form!: VForm;
  valid: boolean = true;
  emailRules = [
    (input: string) => !!input || 'Email wird benötigt',
    (input: string) => /.+@.+\..+/.test(input) || 'E-mail must be valid',
  ];
  nameRules = [
    (input: string) => !!input || 'Name wird benötigt',
    // (input: string) => /.+,+/.test(input) || 'Vollständiger Name wird benötigt',
  ]

  sent: boolean = false;

  formSubmit: FormSubmission = {} as FormSubmission;

  get messageBoxHeight() {
    let messageBoxHeight: number = 150;
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        messageBoxHeight = 150;
        break;
      case 'sm':
        messageBoxHeight = 150;
        break;
      case 'md':
        messageBoxHeight = 400;
        break;
      case 'lg':
        messageBoxHeight = 400;
        break;
      case 'xl':
        messageBoxHeight = 400;
        break;
    }
    return messageBoxHeight;
  }

  get show() {
    return this.isContactFormVisible;
  }

  set show(value) {
    this.$emit('switch-contact-form-visibility', value);
  }

  created() {
    this.$log.debug('--> created()');
  }

  validate() {
    let formIsValid = this.form.validate();
    this.$log.debug('formIsValid = ', formIsValid);
    if (formIsValid) {
      this.$log.debug('We can send an email = ', formIsValid);
    } else {
      this.$log.debug('Form is invalid.');
    }
  }

  submitEmail(e: Event) {
    this.$log.debug('--> submitEmail()');
    this.$log.debug('submission = ', JSON.stringify(this.formSubmit));
    e.preventDefault()
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
        .post(
            'https://formsubmit.co/ajax/c8eeeb3d51f4f40454b2a0057420a59f',
            this.formSubmit)
        .then(response => console.log(response))
        .catch(error => console.log(error));
    this.show = false;
    this.afterSubmission();
  }

  @Emit('show-thank-you-note')
  afterSubmission() {
    this.$log.debug('--> afterSubmission(). Emit a show thank you request');
    return true;
  }


  reset() {
    this.form.reset();
  }

  resetValidation() {
    this.$log.debug('--> resetValidation()');
    this.form.resetValidation();
  }
}
