























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Snackbars extends Vue {
  @Prop() isObjectivesLinkVisible!: boolean;
  @Prop() isProductsLinkVisible!: boolean;
  @Prop() isThankYouVisible!: boolean;
  @Prop() isNoVacancyVisible!: boolean;

  created() {
    this.$log.debug('--> Navigation Drawer created()');
  }

  get showObjectivesLink() {
    return this.isObjectivesLinkVisible;
  }

  set showObjectivesLink(value: boolean) {
    // this.$emit('switch-objectives-snackbar-visibility', value);
    this.closeAllSnackbars();
  }

  get showProductsLink() {
    return this.isProductsLinkVisible;
  }

  set showProductsLink(value: boolean) {
    // this.$emit('switch-products-snackbar-visibility', value);
    this.closeAllSnackbars();
  }

  get showThankYou() {
    return this.isThankYouVisible;
  }

  set showThankYou(value: boolean) {
    this.closeAllSnackbars();
  }

  get showNoVacancy() {
    return this.isNoVacancyVisible;
  }

  set showNoVacancy(value: boolean) {
    this.closeAllSnackbars()
  }

  closeAllSnackbars() {
    this.$emit('close-snackbars');
  }

  closeAllDialogs() {
    this.$emit('close-dialogs');
  }

  routeTo(target: string) {
    this.$log.debug('--> routeTo(). target =', target);
    const currentPath: string = this.$route.path;
    this.$log.debug('current path:', currentPath);
    this.closeAllSnackbars();
    this.closeAllDialogs();
    if (target !== currentPath) {
      this.$router.push({path: target});
    }
  }
}
