





































import {Component, Emit, Vue} from 'vue-property-decorator';
import {mdiEmail, mdiHelpBox, mdiHome} from "@mdi/js";

@Component({
  components: {},
})
export default class Navigation extends Vue {

  homeIcon = mdiHome;
  emailIcon = mdiEmail;
  faqIcon = mdiHelpBox;
  drawer: boolean = false;

  created() {
    this.$log.debug('--> created()');
  }

  routeTo(target: string) {
    this.$log.debug('--> routeTo(). target =', target);
    const currentPath: string = this.$route.path;
    this.$log.debug('current path:', currentPath);
    if (target !== currentPath) {
      this.$router.push({path: target});
    }
  }

  @Emit('show-navigation-drawer')
  toggleNavigationDrawer() {
    this.$log.debug('Need to show navigation drawer');
    this.drawer = !this.drawer;
  }
}
