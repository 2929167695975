
































import {Component, Vue} from "vue-property-decorator";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import ContactForm from "@/components/dialogs/ContactForm.vue";
import Navigation from "@/components/Navigation.vue";
import MetaTags from "@/config/meta-tags.json"
import VueMeta from 'vue-meta'
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import Snackbars from "@/components/dialogs/Snackbars.vue";

@Component({
  components: {
    Snackbars,
    NavigationDrawer,
    AppFooter,
    AppHeader,
    Navigation,
    ContactForm,
  },
  metaInfo(): VueMeta {
    return MetaTags.app;
  }
})

export default class App extends Vue {
  showThankYou: boolean = false;
  show: boolean = true;
  contactDialogIsVisible: boolean = false;
  navigationDrawerIsVisible: boolean = true;
  snackbarMessage: string = "";
  showSnackbar = false;
  showProductsLinkSnackbar: boolean = false;
  showObjectivesLinkSnackbar: boolean = false;
  showNoVacancySnackbar: boolean = false;

  created() {
    this.$log.debug('--> App created()');

    this.$log.debug('<-- App created()');
  }

  toggleContactForm(value: boolean) {
    this.$log.debug('toggleContactForm(). Show contact form request received.');
    this.contactDialogIsVisible = value;
    // this.showProductsLinkSnackbar = value;
    this.showNoVacancySnackbar = value;
    this.$log.debug('contactDialogIsVisible:', this.contactDialogIsVisible);
  }

  toggleThankYouNote() {
    this.$log.debug('toggleThankYouNote(). Request to show thank you note.');
    this.showThankYou = true;
  }

  toggleNavigationDrawer() {
    this.$log.debug('toggleNavigationDrawer(). Request to toggle Navigation drawer.');
    this.navigationDrawerIsVisible = !this.navigationDrawerIsVisible;
    this.$log.debug('toggleNavigationDrawer:', this.navigationDrawerIsVisible);
  }


  onSnackbarCloseRequest() {
    this.$log.debug('--> onSnackbarCloseRequest(). Request to close all snackbars received.');

    this.showObjectivesLinkSnackbar = false;
    this.showProductsLinkSnackbar = false;
    this.showThankYou = false;
  }

  OnDialogsCloseRequest() {
    this.$log.debug('--> OnDialogsCloseRequest(). Request to close all dialogs received.');
    this.contactDialogIsVisible = false;
  }

  onShowObjectivesSnackbar() {
    this.$log.debug('--> onShowObjectivesSnackbar(). Request to show objectives link snackbar received.');
    this.showObjectivesLinkSnackbar = true;
  }


}
