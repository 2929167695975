import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Meta from 'vue-meta'
import HomeView from "@/views/HomeView.vue";

Vue.use(VueRouter)
Vue.use(Meta)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    alias: ['/impressum'],
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/products',
    alias: ['/angebote'],
    name: 'products',
    props: {displayHero: false},
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue')
  },
  {
    path: '/contact',
    alias: ['/kontakt'],
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/legal',
    alias: ['/datenschutz'],
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/LegalView.vue')
  },
  {
    path: '/profile',
    alias: [encodeURI('/über-mich')],
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/objectives',
    alias: [encodeURI('/trainingsgrundsätze'), encodeURI('/grundsätze')],
    name: 'objective',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "objectives" */ '../views/ObjectivesView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
