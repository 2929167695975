



















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ProductPreview from "@/types/ProductPreview";
import vueRemarkable from 'vue-remarkable';
import einzeltrainingMarkdown from "@/config/products/einzeltraining.md"
import raketenstartMarkdown from "@/config/products/raketenstart.md"
import ProductMapping from "@/types/ProductMapping";
import antigiftMarkdown from "@/config/products/antigift.md";
import dummyMarkdown from "@/config/products/dummytraining.md";
import mantrailingMarkdown from "@/config/products/mantrailing.md"
import socialwalkMarkdown from "@/config/products/socialwalk.md"
import junghundeMarkdown from "@/config/products/junghunde.md"
import welpenzeitMarkdown from "@/config/products/welpenzeit.md"
import leinenKursMarkdown from "@/config/products/leinenkurs.md"

@Component({
  components: {
    vueRemarkable,
  },
})
export default class ProductDetails extends Vue {
  @Prop() currentProduct!: ProductPreview;
  @Prop() value!: boolean;
  @Prop() configIndex !: number;
  markdown: Array<ProductMapping> = [] as Array<ProductMapping>;

  currentMarkdown: Object = einzeltrainingMarkdown;
  scrollInvoked: number = 0;

  created() {
    this.$log.debug('--> ProductDetails created()');
    this.$log.debug('--> showProductDetails = ', this.currentProduct);
    this.$log.debug('--> config index = ', this.configIndex);
    this.markdown = [
      {
        id: 0,
        markdown: einzeltrainingMarkdown
      },
      {
        id: 1,
        markdown: junghundeMarkdown
      },
      {
        id: 2,
        markdown: welpenzeitMarkdown
      },
      {
        id: 3,
        markdown: mantrailingMarkdown
      },
      {
        id: 4,
        markdown: dummyMarkdown
      },
      {
        id: 5,
        markdown: socialwalkMarkdown
      },
      {
        id: 6,
        markdown: leinenKursMarkdown
      },
      {
        id: 7,
        markdown: raketenstartMarkdown
      },
      {
        id: 8,
        markdown: antigiftMarkdown
      },
    ] as Array<ProductMapping>;
  }

  addSubUrls() {
    if (this.show) {
      this.$router.push('/angebot');
    }
  }

  onScroll() {
    this.scrollInvoked++;
  }

  closeDialog() {
    this.moveToTop();
    this.show = false
  }

  moveToTop() {
    this.$log.debug('--> moveToTop().');

    let elementById = document.getElementById('requestForm');
    this.$log.debug('--> elementById=', elementById);

    if (elementById != null) {
      elementById.scrollIntoView();
    }

  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  // @Watch('value', {immediate: true, deep: true})
  // loadRouterUrl() {
  //   if (this.show) {
  //     this.$router.push('/angebot/' + this.currentProduct.title);
  //   } else {
  //     this.$router.push('/');
  //   }
  // }


  getImagePath(filename: string) {
    return require('@/assets/' + filename);
  }

  getMarkdown(target: number) {
    this.$log.debug('--> getMarkdown with index =', target);

    let queriedMarkdown: ProductMapping;
    // queriedMarkdown = this.markdown[target];
    queriedMarkdown = this.markdown.find(
        (a) => a.id === target) as ProductMapping;
    this.$log.debug('markdown =', queriedMarkdown.id);
    this.currentMarkdown = queriedMarkdown.markdown;
    return queriedMarkdown.markdown;
  }

  get markdownTarget() {
    return this.currentMarkdown;
  }

  @Emit('show-contact-form')
  showContactForm() {
    this.$log.debug('showContactForm(). Emitting request to show contact form.');
  }

}
