import { render, staticRenderFns } from "./ProductView.vue?vue&type=template&id=d4c86f34&"
import script from "./ProductView.vue?vue&type=script&lang=ts&"
export * from "./ProductView.vue?vue&type=script&lang=ts&"
import style0 from "./ProductView.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports