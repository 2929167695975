































import {Component, Emit, Vue} from 'vue-property-decorator';
import ProductView from "@/views/ProductView.vue";
import ObjectivesView from "@/views/ObjectivesView.vue";
import ProfileView from "@/views/ProfileView.vue";
import VueMeta from 'vue-meta'
import metaTags from "@/config/meta-tags.json"
import ImageInfo from "@/types/ImageInfo";
import ImageSeo from "@/config/images-info.json"

@Component({
  components: {
    ProfileView,
    ObjectivesView,
    ProductView
  },
  metaInfo(): VueMeta {
    return metaTags.home;
  }
})

export default class HomeView extends Vue {

  imageInfo: ImageInfo = {} as ImageInfo;

  created() {
    this.$log.debug('--> HomeView() created');
    this.imageInfo = ImageSeo.home;
    this.$log.debug('<-- HomeView(). Bye now!');
  }

  @Emit('show-contact-form')
  showContactForm() {
    this.$log.debug('Need to show dialog');
    return true;
  }

}
