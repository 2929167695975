import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                primary: '#0D355A',
                secondary: '#6E98C0',
                accent: '#C1DEDF',
                error: '#b71c1c',
            },
        },
    },
});
