


































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import configuration from "@/config/products/productPreviews.json";
import ProductPreview from "@/types/ProductPreview";
import ProductDetails from "@/components/dialogs/ProductDetails.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import PageContent from "@/types/PageContent";
import imagesInfo from "@/config/images-info.json"


@Component({
  components: {
    ProductDetails,
    VueSlickCarousel,
  },
})
export default class ProductCarousel extends Vue {
  @Prop() displayHero!: boolean;

  config: Array<ProductPreview> = [];
  heroConfig: PageContent = {} as PageContent;
  showTextPreview: boolean = false;
  showProductDetails: boolean = false;
  previewText: string = ""
  currentView: ProductPreview = {} as ProductPreview;
  configIndex: number = 0;


  slickOptions = {
    "arrows": true,
    "dots": true,
    "infinite": true,
    "accessibility": false,
    "speed": 500,
    "slidesToShow": 3,
    "slidesToScroll": 3,
    "initialSlide": 0,
    "responsive": [
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 3,
          "slidesToScroll": 3,
          "infinite": true,
          "dots": true,
          "arrows": true,
        }
      },
      {
        "breakpoint": 850,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2,
          "initialSlide": 2,
          "dots": true,
        }
      },
      {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
        }
      }
    ]
  };

  mounted() {
    if (this.displayHero) {
      this.slickOptions.arrows = false;
    }
  }


  created() {
    this.$log.debug('--> ProfileView() created.');
    this.config = configuration.productPreviews;
    this.heroConfig = imagesInfo.home;
    this.currentView = this.config[0];
  }

  getImagePath(filename: string) {
    let imagePath = require('../assets/' + filename);
    // this.$log.debug('--> getImagePath(). Return:', imagePath);
    return imagePath;
  }

  get isMobile() {
    if (this.$vuetify.breakpoint.xs && this.$vuetify.breakpoint.width <= 480) {
      return true;
    } else {
      return false;
    }
  }

  togglePreview(title: string) {
    this.previewText = title;
    this.showTextPreview = true;
  }

  toggleDetailsView() {
    this.$log.debug('--> toggleDetailsView().');
    this.showProductDetails = true;
    this.$log.debug('--> showProductDetails = ', this.showProductDetails);
  }

  updateProductDetailsView(index: number) {
    this.$log.debug('--> clickMessage(). Index =', index);
    this.currentView = this.config[index];
    this.$log.debug('--> CurrentView is now:', this.currentView.title);
    this.showProductDetails = true;
    this.configIndex = index;
  }

  @Emit('show-contact-form')
  showContactForm() {
    this.$log.debug('showContactForm(). Emitting request to show contact form.');
    return true;
  }
}
