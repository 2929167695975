


















import {Component, Vue} from 'vue-property-decorator';
import vueRemarkable from 'vue-remarkable';
import objectives from "@/config/objectives.md"
import configuration from "@/config/images-info.json"
import PageContent from "@/types/PageContent";
import VueMeta from 'vue-meta'
import MetaTags from "@/config/meta-tags.json";

@Component({
  components: {
    vueRemarkable,
  },
  metaInfo(): VueMeta {
    return MetaTags.objectives;
  }
})

export default class ObjectivesView extends Vue {
  markdown = objectives;
  config: PageContent = {} as PageContent;
  image: string = "";

  created() {
    this.$log.debug('--> HomeView() created');
    this.config = configuration.objectives;
    this.image = this.config.file;

    this.$log.debug('<-- HomeView(). Bye now!');
  }

  getImagePath(filename: string) {
    return require('../assets/' + filename);
  }

}
