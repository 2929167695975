


























import {Component, Vue} from 'vue-property-decorator';
import PageContent from "@/types/PageContent";
import vueRemarkable from 'vue-remarkable';
import profileMarkdown from "@/config/profile.md"
import VueMeta from 'vue-meta'
import MetaTags from "@/config/meta-tags.json";
import ImageSeo from "@/config/images-info.json"
import ImageInfo from "@/types/ImageInfo";

@Component({
  components: {
    vueRemarkable
  },
  metaInfo(): VueMeta {
    return MetaTags.profileView;
  }
})
export default class ProfileView extends Vue {
  config: PageContent = {} as PageContent;
  markdown = profileMarkdown;
  imageInfo: ImageInfo = {} as ImageInfo;

  created() {
    this.$log.debug('--> ProfileView() created.');
    this.$log.debug('config:', this.config);
    this.imageInfo = ImageSeo.profile;
  }

  getImagePath(filename: string) {
    return require('../assets/' + filename);
  }

  mounted() {
    this.$log.debug('--> AboutView() mounted');

    this.$emit('show-objectives-snackbar', true);
  }


}
