



































import {Component, Vue} from 'vue-property-decorator';
import {mdiFacebook, mdiInstagram} from "@mdi/js";

@Component({
  components: {},
})
export default class AppFooter extends Vue {

  instagramIcon = mdiInstagram;
  facebookIcon = mdiFacebook;


  created() {
    this.$log.debug('--> created()');
  }
}
