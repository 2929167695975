











import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import configuration from "@/config/products/productPreviews.json";
import ProductPreview from "@/types/ProductPreview";
import ProductDetails from "@/components/dialogs/ProductDetails.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ProductCarousel from "@/components/ProductCarousel.vue";

@Component({
  components: {
    ProductCarousel,
    ProductDetails,
    VueSlickCarousel,
  },
})
export default class ProductView extends Vue {
  @Prop() displayHero!: boolean;
  config: Array<ProductPreview> = [];
  showTextPreview: boolean = false;
  showProductDetails: boolean = false;
  previewText: string = ""
  currentView: ProductPreview = {} as ProductPreview;
  configIndex: number = 0;


  created() {
    this.$log.debug('--> ProfileView() created. ShowHero =', this.displayHero);
    this.config = configuration.productPreviews;
    this.currentView = this.config[0];
  }

  getImagePath(filename: string) {
    let imagePath = require('../assets/' + filename);
    // this.$log.debug('--> getImagePath(). Return:', imagePath);
    return imagePath;
  }

  get isMobile() {
    if (this.$vuetify.breakpoint.xs && this.$vuetify.breakpoint.width <= 480) {
      return true;
    } else {
      return false;
    }
  }

  togglePreview(title: string) {
    this.previewText = title;
    this.showTextPreview = true;
  }

  toggleDetailsView() {
    this.$log.debug('--> toggleDetailsView().');
    this.showProductDetails = true;
    this.$log.debug('--> showProductDetails = ', this.showProductDetails);
  }

  updateProductDetailsView(index: number) {
    this.$log.debug('--> clickMessage(). Index =', index);
    this.currentView = this.config[index];
    this.$log.debug('--> CurrentView is now:', this.currentView.title);
    this.showProductDetails = true;
    this.configIndex = index;
  }

  @Emit('show-contact-form')
  showContactForm() {
    this.$log.debug('showContactForm(). Emitting request to show contact form.');
  }
}
