














































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mdiDogSide, mdiEmail, mdiHelpBox, mdiHome, mdiInformation, mdiRoutes} from "@mdi/js";

@Component({
  components: {},
})
export default class NavigationDrawer extends Vue {
  @Prop() isVisible!: boolean;
  show = true;
  homeIcon = mdiHome;
  emailIcon = mdiEmail;
  faqIcon = mdiHelpBox;
  productIcon = mdiDogSide;
  aboutMeIcon = mdiInformation;
  objectivesIcon = mdiRoutes;

  created() {
    this.$log.debug('--> Navigation Drawer created()');
  }

  @Watch('isVisible', {immediate: true, deep: true})
  onIsVisibleChange() {
    this.$log.debug('--> onIsVisibleChange(). Now set to: ', this.isVisible);
    this.show = !this.show;
  }

  routeTo(target: string) {
    this.$log.debug('--> routeTo(). target =', target);
    const currentPath: string = this.$route.path;
    this.$log.debug('current path:', currentPath);
    if (target !== currentPath) {
      this.$router.push({path: target});
    }
  }
}
