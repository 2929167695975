



















import {Component, Vue} from 'vue-property-decorator';
import Navigation from "@/components/Navigation.vue";


@Component({
  components: {
    Navigation
  },
})
export default class AppHeader extends Vue {

  created() {
    this.$log.debug('--> created()');
  }

  routeTo(target: string) {
    this.$log.debug('--> routeTo(). target =', target);
    const currentPath: string = this.$route.path;
    this.$log.debug('current path:', currentPath);
    if (target !== currentPath) {
      this.$router.push({path: target});
    }
  }

}
